import React from 'react';
import MainLayout from '../layouts/MainLayout';
import VTS from '../components/VTS';

const VTSPage = () => {
    return (
        <MainLayout>
            <VTS />
        </MainLayout>
    )
}

export default VTSPage