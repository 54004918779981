import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

const Home = () => {
    const magazinesNum = [2, 3, 4];

    const [magazines, setMagazines] = useState([]);

    const getMagazines = async () => {
        const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/magazines/`)
        setMagazines(await response.json())
    };

    useEffect(() => {
        getMagazines()
    }, []);

    useEffect(() => {
        console.log(magazines);

    }, [magazines])

    return (
        <div className='d-flex justify-content-center pt-5'>
            <div className='myMaxW1600 d-flex justify-content-center flex-wrap'>
                {
                    magazines && magazines.map((el) => {
                        return (
                            <div className='shadow rounded-5 m-3 pb-2'>
                                <img className='myMaxW500 w-100 rounded-5' src={`${el.image}`} alt="" />
                                <div className='mb-2 d-flex justify-content-center gap-2'>
                                    <Button className='rounded-5 px-3 fw-normal text-light myBgAqua border' href={`${el.download}`} target='_blank'><i className="bi bi-download"></i> Download</Button>
                                    <Button className='rounded-5 px-3 fw-normal text-light border' variant='secondary' href={`${el.link}`} target='_blank'><i className="bi bi-newspaper"></i> Read on-line</Button>
                                </div>
                                <h4 className='text-center text-secondary'>{el.anno}</h4>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Home