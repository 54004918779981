import React, { Children } from 'react';
import NavigationBar from '../components/NavigationBar';

const MainLayout = ({ children }) => {
    return (
        <div>
            <NavigationBar/>
            <main style={{paddingTop: '14px'}}>
            <div className='mb-5'></div>
                {children}
            </main>
        </div>
    )
}

export default MainLayout