import React, { useState } from 'react';
import paperMagazine from '../assets/json/paperMagazine.json';
import tabloidBg from '../assets/images/tabloidBg.jpg';
import magazineCirculation from '../assets/images/magazineCirculation.png';
import MainLayout from '../layouts/MainLayout';

const About = () => {

    const [loopIndex, setLoopIndex] = useState(0);

    const counter = (input) => {
        if (loopIndex === 6) {
            setLoopIndex(0)
        } else {
            input ? setLoopIndex(loopIndex + 1) : setLoopIndex(loopIndex - 1)
        }
    }

    return (
        <MainLayout>
            <div style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

                <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${tabloidBg})` }}>
                    <div className='px-4 py-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
                        <h1 className='montserrat-alternates-bold'>Paper Magazines</h1>
                        <h2 className='font-nycd text-info my-5'>OUR LEGACY IS ROOTED ON PAPER.</h2>
                        <h3 className='fw-light myMaxWidth1600'>It’s not just a matter of charm: printed magazines offer an impactful and long-lasting experience, both visible and tangible, and a professional feel that is more easily remembered and respected.
                        </h3>
                    </div>
                </div>


                <div className='py-4'>
                    <div className='spacerLg'>{/* adaptive spacer */}</div>
                    <div className='infinite_carousel d-flex justify-content-center align-items-center'>
                        {
                            paperMagazine && paperMagazine.map((el, index) => {
                                if (index === loopIndex || index === loopIndex - 1 || index === loopIndex + 1) {
                                    return <div><img className={`${index === loopIndex ? "myMagazineHeightMax" : "myMagazineHeightMin"} w-100`} src={require(`../assets/images/${el.img}`)} alt="img" /></div>
                                }
                            })
                        }
                    </div>
                </div>

                <div>{/* elemento grafico */}
                    <div className='d-flex flex-column align-items-center'>
                        <div className='myBgBlue rounded-5' style={{ height: "20px", width: "20px" }}></div>
                        <div className='myBgBlue' style={{ height: "100px", width: "7px" }}></div>
                        <div className='myBgBlue rounded-5' style={{ height: "30px", width: "30px" }}></div>
                    </div>
                </div>

                <div className='py-5 px-4 '>
                    {
                        paperMagazine && paperMagazine.map((el, index) => {
                            if (index === loopIndex) {
                                return (
                                    <div className='d-flex flex-column align-items-center text-center'>


                                        {/* General Info */}
                                        <h1 className='display-4 montserrat-alternates-bold fw-bold myTextDark'>{el.name}</h1>
                                        <h2 className='font-nycd text-info my-5 mt-1 text-center'>{el.slogan}</h2>
                                        <h3 className='text-secondary myTextDark myMaxWidth1400 text-center'>{el.description}</h3>

                                        <div className='mt-5'>{/* elemento grafico */}
                                            <div className='d-flex flex-column align-items-center'>
                                                <div className='bg-info rounded-5' style={{ height: "30px", width: "30px" }}></div>
                                                <div className='bg-info' style={{ height: "100px", width: "7px" }}></div>
                                                <div className='bg-info rounded-5' style={{ height: "20px", width: "20px" }}></div>
                                            </div>
                                        </div>

                                        <h2 className='myTextDark mt-5'><i className="bi bi-clock-history me-3"></i>{el.publishing}</h2>
                                        <h3 className='myTextDark mt-1 montserrat-alternates-light'><i className="bi bi-calendar2-week me-3"></i>{el.info.publishData.synt}</h3>
                                        <h2 className='myTextDark mt-5'><i className="bi bi-newspaper me-3"></i> {el.info.size}</h2>
                                        <h3 className='myTextDark mt-5 mb-5'><i className="bi bi-translate me-2"></i> {el.language}</h3>

                                        {/* Stats */}
                                        <div className='bg-dark text-light p-4 mt-5 rounded-1 border myMaxWidth1200'>
                                            <h1 className='fw-light'>Stats</h1>
                                            <hr />

                                            <div className='mb-5 d-flex flex-wrap justify-content-center'>
                                                <div className='d-flex align-items-center me-5'>
                                                    <div className='myBgAcqua rounded-5' style={{ height: "10px", width: "10px" }}></div>
                                                    <h5 className='m-0 ps-2 fw-light'>Hard copies / year</h5>
                                                </div>
                                                <div className='d-flex align-items-center me-5'>
                                                    <div className='myBgFucsia rounded-5' style={{ height: "10px", width: "10px" }}></div>
                                                    <h5 className='m-0 ps-2 fw-light'>Hard copies / issue</h5>
                                                </div>
                                                <div className='d-flex align-items-center me-5'>
                                                    <div className='rounded-5' style={{ height: "10px", width: "10px", backgroundColor: "#285e9f" }}></div>
                                                    <h5 className='m-0 ps-2 fw-light'>Soft copies / issue</h5>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-wrap gap-4 justify-content-center mb-5'>
                                                <div className='myNumberCircle d-flex align-items-center justify-content-center display-6 myTextAcqua montserrat-alternates-medium' style={{ border: "5px solid #14a7ad" }}>
                                                    {el.info.hardCopiesYear / 1000}K
                                                </div>
                                                <div className='myNumberCircle d-flex align-items-center justify-content-center display-6 myTextFucsia montserrat-alternates-medium' style={{ border: "5px solid #e82283" }}>
                                                    {Math.round(el.info.hardCopiesYear / (el.info.nIssue * 1000))}K
                                                </div>
                                                <div className='myNumberCircle d-flex align-items-center justify-content-center display-6 font-nycd montserrat-alternates-medium' style={{ border: "5px solid #285e9f", color: "#285e9f" }}>
                                                    {Math.round(el.info.softCopiesYear / (el.info.nIssue * 1000))}K
                                                </div>
                                            </div>

                                            <hr />

                                            <div>
                                                <div className='my-4'>
                                                    <h3 className='text-info'>Hard Copies: </h3>
                                                    <h4 className='fw-light'>{el.audience.hardCopies}</h4>
                                                </div>
                                                <div className='my-4'>
                                                    <h3 className='text-primary'>Soft Copies: </h3>
                                                    <h4 className='fw-light'>{el.audience.softCopies}</h4>
                                                </div>
                                            </div>

                                        </div>


                                        <div className='mt-5'>{/* elemento grafico */}
                                            <div className='d-flex flex-column align-items-center'>
                                                <div className='myBgBlue rounded-5' style={{ height: "20px", width: "20px" }}></div>
                                                <div className='myBgBlue' style={{ height: "100px", width: "7px" }}></div>
                                                <div className='myBgBlue rounded-5' style={{ height: "30px", width: "30px" }}></div>
                                            </div>
                                        </div>


                                        {/* Calendar */}

                                        <div className='mt-5'>
                                            {
                                                paperMagazine && paperMagazine.map((el, index) => {
                                                    if (index === loopIndex) {
                                                        return (
                                                            <div>
                                                                {
                                                                    el.issues.map((el, index) => {
                                                                        return <h1 className='montserrat-alternates-light mb-4 p-2 rounded' style={{ border: "3px solid #14a7ad" }}><span className='pe-2 me-4 text-info montserrat-alternates-bold' style={{ borderRight: "2px solid #14a7ad" }}># {index + 1}</span>{el.date}</h1>
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>


                                    </div>
                                )
                            }
                        })
                    }
                </div>


                {/* part 2 */}
                <div className='myBgDarkgray mt-5 py-5 text-light'>
                    <div >
                        <div className='d-flex justify-content-center'>
                            <div className='p-2 px-3 pt-5 text-center' style={{ maxWidth: "700px" }}>
                                <h1>Our Dental & Medical Magazines are distributed Worldwide</h1>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center py-5'>
                        <img className='w-100' src={magazineCirculation} alt="" style={{ maxWidth: "1000px" }} />
                    </div>
                    <div style={{ height: "100px" }}>{/* my spacer */}</div>
                </div>




            </div>
        </MainLayout>
    )
}

export default About