import React, { useEffect, useState } from 'react';
import VTSCard from './VTSCard';

const VTS = () => {

    const [data, setData] = useState([]);


    const getAllProducts = async () => {
        const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/virtualtradeshow/`)
        setData(await response.json())
    };

    useEffect(() => {
        getAllProducts()
    }, []);



    return (
        <div className='d-flex justify-content-center pt-3'>
            <div className='myMaxW1000 d-flex flex-wrap justify-content-center'>
                {
                    data && data.length ?
                        data.map((el) => {
                            return <VTSCard singleData={el} />
                        })
                        : null
                }
            </div>
        </div>
    )
}

export default VTS