import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const Article = () => {

    const { id } = useParams();
    const [data, setData] = useState({});

    const getSingleArticle = async () => {

        const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/virtualtradeshow/article/${id}`)
        setData(await response.json())

    };

    useEffect(() => {
        getSingleArticle();
    }, []);


    return (
        <div className='d-flex justify-content-center pb-5'>
            <div className='myMaxW1000 p-3 pt-1'>
                {
                    data ?
                        <div className='d-flex flex-wrap justify-content-center'>
                            <div className='myMaxW650'>
                                <h1 className='my-4'>{data.nomeprodotto}</h1>
                                <h1 className='mt-3 fw-light' dangerouslySetInnerHTML={{ __html: data.descrizione }}></h1>
                            </div>
                            <div className='myMaxW300 pt-4'>
                                <div className='border p-4 rounded-5 d-flex flex-column align-items-center bg-light'>
                                    <img className="w-100" src={`https://www.infodentinternational.com/images/companies/312x150_${data.idr}.jpg`} alt="" />
                                    {data && data.infoazienda ? <a className='myFont15rem noLinkStyle' href={`${data.infoazienda.split('"')[1]}`} target='_blank'>{data.infoazienda.split('"')[2].split("</a>")[0].slice(1)}</a> : null}
                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>
        </div>
    )
}

export default Article