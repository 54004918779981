import React from 'react'
import { Link } from 'react-router-dom'

const VTSCard = ({ singleData }) => {
    return (
        <Link className='noLinkStyle' to={`/article/${singleData.id}`}>
            <div className='text-dark myMaxW450 border m-2 p-3 rounded-5 shadow-sm'>
                <img className="w-100" src={`https://www.implant-book.com/images/articles/img_${singleData.id}.jpg`} alt="" />
                <div className='p-2 myDarkGray'>
                    <h4 className='mt-3'>{singleData.nomeprodotto}</h4>
                    <h6>{singleData.descrizione}</h6>
                    {singleData && singleData.infoazienda ? <a href={`${singleData.infoazienda.split('"')[1]}`}>{singleData.infoazienda.split('"')[2].split("</a>")[0].slice(1)}</a> : null}
                </div>
            </div>
        </Link>
    )
}

export default VTSCard