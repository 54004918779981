import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary shadow-sm position-fixed w-100">
            <Container>
                <Link className='noLinkStyle navBarFocus py-1' to={'/'}><Navbar.Brand>ImplantBOOK</Navbar.Brand></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link className='noLinkStyle navBarFocus'><Nav.Link href="#features"><Link className='noLinkStyle navBarFocus' to={'/about'}>About ImplantBOOK</Link></Nav.Link></Link>
                        <Nav.Link><Link className='noLinkStyle navBarFocus' to={'/virtual-trade-show'}>Virtual Trade Show</Link></Nav.Link>
                        <Link className='noLinkStyle navBarFocus' to={'/'}><Nav.Link><Link className='noLinkStyle navBarFocus'   to={'/contacts'}>Contacts</Link></Nav.Link></Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBar