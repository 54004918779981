import React from 'react';
import MainLayout from '../layouts/MainLayout';
import Article from '../components/Article';

const ArticlePage = () => {
    return (
        <MainLayout>
            <Article />
        </MainLayout>
    )
}

export default ArticlePage