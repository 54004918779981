import React from 'react'
import MainLayout from '../layouts/MainLayout'

const ContactsPage = () => {
    return (
        <MainLayout>
            <div className='pt-4  text-center' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>
                <h1 className='fw-light'><i>Contact Us</i></h1>

                <div className='d-flex flex-wrap justify-content-center'>

                    <div className='bg-light border p-3 rounded-5 m-3 mt-4 myMaxWidth700 myBgTransparent20' >
                        <h6>Headquarters:</h6>
                        <hr />
                        <p><i className="bi bi-geo-alt me-2"></i>Via Dell'industria, 65 - 01100 Viterbo - Italy</p>
                        <p><i className="bi bi-telephone me-2"></i> +39 0761 352198</p>
                        <p><i className="bi bi-envelope-at me-2"></i>privacy@infodent.com</p>
                    </div>

                    <div className='bg-light border p-3 rounded-5 m-3 mt-4 myMaxWidth700 myBgTransparent20' >
                        <h6>Registered Office:</h6>
                        <hr />
                        <p><i className="bi bi-geo-alt me-2"></i>C.ne Gianicolense, 68 - 00152 Rome - Italy</p>
                        <p>VAT: IT01612570562</p>
                    </div>

                </div>

            </div>
        </MainLayout>
    )
}

export default ContactsPage